<template>
  <div v-if="qs">
    <div>
      <Detail @close-drawer="closeDrawer"
              v-if="tableData && tableData.data.length"
              :data="dailyData"
              :is-open="openDrawer"
      />
    </div>
    <Card>
      <!-- <p slot="title">
      <template v-if="reportView">
        List of reports ({{ tableData ? tableData.data.length : '...' }})
      </template>
      <template v-else>
        List of notifications for report #{{ selectedReport }}
      </template>
    </p> -->
      <!-- <template v-if="!loading"> -->
      <template v-if="current">
        <Steps :current="current"
               size="small"
        >
          <Step :title="`List of reports ( ${tableData ? tableData.pagination.count : '...'} )`" />
          <Step v-if="current !== 0"
                :title="`List of notifications for report #${selectedReport}`"
          />
        </Steps>
        <br>
        <a style="font-size:18px"
           @click="backNav"
        ><i v-if="current !== 0"
          
            class="fas fa-arrow-left"
        /></a>
        <br>
        <br>
        <p v-if="tableData && current==1">
          Weekly report detail for the property : 
          <strong>{{ property.displayName }}</strong><br>
          Week n° {{ moment(tableData.data.find(elm => elm.id === selectedReport).date).isoWeek() }} <br>
          From <strong>{{ moment(tableData.data.find(elm => elm.id === selectedReport).date).subtract(6, 'day').format('LL') }}</strong> to <strong>{{ moment(tableData.data.find(elm => elm.id === selectedReport).date).format('LL') }}</strong>
        </p>      <br>
        <br>
      </template>
      <!-- <Alert type="warning"
             show-icon
             v-if="$route.query.propertyId && properties.find(elm => elm.id === parseInt($route.query.propertyId))"
      >
        This report is from property <strong>{{ properties.find(elm => elm.id === parseInt($route.query.propertyId)).displayName }}</strong>
      </Alert> -->
      <FilterTable v-if="reportView" 
                   :columns="columns"
                   name="List of reports"
                   namespace="notifications"
                   :show-columns-selector="false"
                   @on-items-fetched="callbackFetch"
                   :fetch-items-params="{properties: [property.id], userId: [currentUser.id]}"
                   :default-paging="{sort:'NotificationEmails.id', direction: 'desc'}"

                   :fetch-items="getNotificationEmails"
      />
      <!--<Table v-else
             :span-method="handleSpan"
             :data="selectedNotifications"
             :columns="fixedColumns"
      />-->
      <div v-else>
        <Card style="margin-bottom:15px; padding-bottom:15px">
          <p slot="title">
            A - Vendor and trackers ecosystem evolution
          </p>
          <span>These alerts are triggered if a new tracker or a new vendors has been detected. By new we mean a tracker or a vendor that has not been identified in the previous four weeks.<br><br>
            In all cases you should consider assessing the level of risk and related corrective actions</span> <br><br>
          <h4># 1 - New trackers identification</h4><br>
          <strong># 1.1 - Non exempted 1st party tracker breach</strong><br><br>
          <span>We identified {{ selectedNotifications.find(elm => elm.title === 'Non exempted 1st party tracker breach').value }} new* non exempted 1st party trackers</span>
          <Button type="primary"
                  v-if="selectedNotifications.find(elm => elm.title === 'Non exempted 1st party tracker breach').value"
                  @click="openDetail(selectedNotifications.find(elm => elm.title === 'Non exempted 1st party tracker breach'))"
                  style="float:right; margin:10px 5px"
          >
            More details
          </Button>
          <br><br><br>
          <strong># 1.2 - Non exempted 3rd party tracker breach</strong><br><br>
          <span>We identified {{ selectedNotifications.find(elm => elm.title === 'Non exempted 3rd party tracker breach').value }} new* non exempted 3rd party trackers.</span>
          <Button type="primary"
                  v-if="selectedNotifications.find(elm => elm.title === 'Non exempted 3rd party tracker breach').value"
                  @click="openDetail(selectedNotifications.find(elm => elm.title === 'Non exempted 3rd party tracker breach'))"
                  style="float:right; margin:10px 5px"
          >
            More details
          </Button>
          <Divider />
          <h4># 2 - New Vendor identification</h4><br>
          <strong># 2.1 - Vendor breach</strong><br><br>
          <span>We identified {{ selectedNotifications.find(elm => elm.title === 'Vendor breach').value }} new* vendors.</span>
          <Button type="primary"
                  v-if="selectedNotifications.find(elm => elm.title === 'Vendor breach').value"
                  @click="openDetail(selectedNotifications.find(elm => elm.title === 'Vendor breach'))"
                  style="float:right; margin:10px 5px"
          >
            More details
          </Button>
        </Card>
        <Card style="width:100%; padding-bottom:25px">
          <p slot="title">
            B - Last week privacy events
          </p>
          <span>These alerts are triggered if a non exempted tracker has been activated when consent is not explicitly obtained.<br><br>
            In all cases you should consider assessing the level of risk and related corrective actions.<br><br>
            To carry out this identification/correction process we provide you with the list of urls where the corrupted trackers have been identified.</span> <br><br>
          <h4># 3 - Last week non exempted trackers activated without explicit choice</h4><br>
          <span style="clear:both"><strong># 3.1 - Non exempted 1st party trackers without explicit choice</strong></span>
          <br>
          <br>
          <span>{{ countDataFor('Non exempted 1st party trackers without explicit choice') }}</span>
          <Table :columns="simpleTableCols"
                 style="margin:10px 0;"
                 :data="simpleTableData('Non exempted 1st party trackers without explicit choice')"
          />
          <Button type="primary"
                  v-if="isDataFor('Non exempted 1st party trackers without explicit choice')"
                  @click="openDetail(selectedNotifications.find(elm => elm.title === 'Non exempted 1st party trackers without explicit choice'))"
                  style="float:right; margin-bottom:10px"
          >
            Daily details
          </Button>
          
          <br><br><br>
          <span style="clear:both"><strong># 3.2 - Non exempted 3rd party trackers without explicit choice
          </strong> </span>
          <br><br>
          <span>{{ countDataFor('Non exempted 3rd party trackers without explicit choice') }}</span>
          <Table :columns="simpleTableCols"
                 style="margin:10px 0;"
                 :data="simpleTableData('Non exempted 3rd party trackers without explicit choice')"
          />
          <Button type="primary"
                  v-if="isDataFor('Non exempted 3rd party trackers without explicit choice')"
                  @click="openDetail(selectedNotifications.find(elm => elm.title === 'Non exempted 3rd party trackers without explicit choice'))"
                  style="float:right; margin-bottom:10px"
          >
            Daily details
          </Button>
          
          <Divider />
          <h4># 4 - Last week non exempted trackers activated despite refusal </h4><br>
          <span style="clear:both"><strong># 4.1 - Non exempted 1st party trackers despite refusal</strong>  </span>
          <br><br>
          <span>{{ countDataFor('Non exempted 1st party trackers despite refusal') }}</span>
          <Table :columns="simpleTableCols"
                 style="margin:10px 0;"
                 :data="simpleTableData('Non exempted 1st party trackers despite refusal')"
          />
          <Button type="primary"
                  v-if="isDataFor('Non exempted 1st party trackers despite refusal')"
                  @click="openDetail(selectedNotifications.find(elm => elm.title === 'Non exempted 1st party trackers despite refusal'))"
                  style="float:right; margin-bottom:10px"
          >
            Daily details
          </Button>
         
          <br><br><br>
          <span style="clear:both"><strong># 4.2 - Non exempted 3rd party trackers despite refusal</strong> </span>
          <br><br>
          <span>{{ countDataFor('Non exempted 3rd party trackers despite refusal') }}</span>
          <Table :columns="simpleTableCols"
                 style="margin:10px 0;"
                 :data="simpleTableData('Non exempted 3rd party trackers despite refusal')"
          />
          <Button type="primary"
                  v-if="isDataFor('Non exempted 3rd party trackers despite refusal')"
                  @click="openDetail(selectedNotifications.find(elm => elm.title === 'Non exempted 3rd party trackers despite refusal'))"
                  style="float:right; margin-bottom:10px"
          >
            Daily details
          </Button>
          
          <Divider />
          <h4>
            # 5 - Last week non exempted trackers having lifetime longer than 13 months
          </h4><br>
          <span style="clear:both"><strong># 5.1 - Trackers detected having lifetime longer than 13 months</strong></span>
          <br><br>
          <span>{{ countDataFor('Trackers detected having lifetime longer than 13 months') }}</span>
          <Table :columns="simpleTableCols"
                 style="margin:10px 0;"
                 :data="simpleTableData('Trackers detected having lifetime longer than 13 months')"
          />
          <Button type="primary"
                  v-if="isDataFor('Trackers detected having lifetime longer than 13 months')"
                  @click="openDetail(selectedNotifications.find(elm => elm.title === 'Trackers detected having lifetime longer than 13 months'))"
                  style="float:right; margin-bottom:10px"
          >
            Daily details
          </Button>
        </Card>
      </div>
    <!-- </template> -->
    </Card>
  </div>
</template>
<script>
import FilterTable from '@/components/ui/FilterTable'
import tableColumn from '@/mixins/tableColumn'
import Detail from './Detail.vue'
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
export default {
  name: 'Notifications',
  components: {FilterTable, Detail},
  mixins: [tableColumn],
  data () {
    return {
      activeTab: 'Notifications',
      notifByProperty: {},
      moment: moment,
      loading: true,
      current:0,
      reportView: true,
      selectedReport: 0,
      selectedNotif: null,
      tableData: false,
      openDrawer: false,
      notificationEmails: [],
      mapText: {
        "Non exempted 1st party tracker breach": (amount) => `We identified ${amount} new non exempted 1st party tracker (did not appear last 4 weeks)`,
        "Non exempted 3rd party tracker breach": (amount) => `We identified ${amount} new non exempted 3rd party tracker (did not appear last 4 weeks)`,
        "Vendor breach": (amount) => `We identified ${amount} new vendors (did not appear last 4 weeks).`,
        "default": (amount) => `${amount} trackers indentified during the week n°${moment(this.tableData.data[0].date).isoWeek()}`
      },
      simpleTableCols: [
        {
          title: 'Day',
          key: 'day'
        },
        {
          title: 'Date',
          key: 'date'
        },
        {
          title: 'Nb trackers',
          key: 'tracker'
        }
      ]
    }
  },
  
  mounted () {
    const route = this.$route
    this.loading = true
    // automatic access to report view
    if (this.$route.query.reportId && this.$route.query.propertyId) {
      return this.getSpecificReport(this.$route.query.reportId, this.$route.query.propertyId)
        .then(r => {
          if (r.data.length) {
            this.tableData = r
            this.openReport({id: this.$route.query.reportId})
            this.loading = false
          } else {
            this.$Modal.error({
              title: 'the notification does\'t exist in this report'
            })
            this.$router.push('/')
            this.loading = false
          }

        })
        .catch(er => {
          this.$Modal.error({
            title: 'the notification does\'t exist in this report'
          })
          this.$router.push('/')
        })
      
    }
  },
  watch: {
    qs: {
      deep: true,
      handler: function (val, old) {
        this.current = 0
        this.reportView = true
      }
    }
  },
  computed: {
    ...mapGetters({
      qs: 'scope/qs',
      property: 'scope/selectedProperty',
      properties: 'scope/properties',
      currentUser: 'user/user'
    }),
    propertyToUse () {
      if (this.$route.query.propertyId) {
        const prop = this.properties.find(elm => elm.propertyId === parseInt(this.$route.query.propertyId))
        return prop
          ? prop.id
          : this.property.id
      }
      return this.property.id
    },
    selectedNotifications () {
      const { tableData, selectedReport } = this
      return tableData.data.find(elm => elm.id === selectedReport).detail_json.notifications
    },
    dailyData () {
      return {
        tableData: this.tableData.data,
        selectedNotif: this.selectedNotif,
        selectedReport: this.selectedReport
      }
    },
    fixedColumns () {
      return [
        {
          key: 'title',
          title: 'Notification name'
        },
        {
          title: 'Amount',
          render: (h, params) => h('span', {}, 
            this.mapText[params.row.title] ? this.mapText[params.row.title](params.row.value) : this.mapText['default'](params.row.value)
          )
        },
        this.createColumn(this.$t('Detail'), null, null, row => ({
          tag: 'Button',
          classes: 'btn btn--elevated',
          props: {type: 'primary'},
          events: {click: () => this.openDetail(row)},
          content: this.mapText[row.title] ? 'Breach list' : this.$t('Daily detail')
        }), 150)
      ]
    } ,
    columns () {
      return [
        {
          title: 'Week #',
          key: 'date',
          render: (h, params) => {
            return h('span', {}, moment(params.row.date).isoWeek() + ' - from ' + moment(params.row.date).subtract(6, 'day').format('LL') + ' to ' + moment(params.row.date).format('LL'))
          }
        },
        this.createColumn(this.$t('Detail'), null, null, row => ({
          tag: 'Button',
          classes: 'btn btn--elevated',
          props: {type: 'primary'},
          events: {click: () => this.openReport(row)},
          content: this.$t('Detail')
        }), 200)
      ]
    }
  },
  methods: {
    ...mapActions({
      getNotificationEmails: 'notifications/getNotificationEmails',
      setSelectedProperty: 'scope/setSelectedProperty',
      setSelectedDaterange: 'scope/setSelectedDaterange',
    }),
    simpleTableData (title) {
      const data = this.selectedNotifications.find(elm => elm.title === title)
      const formatedData = []
      data.table.forEach(table => {
        formatedData.push({
          day: table[0],
          date: table[1],
          tracker: table[2]
        })
      })
      return formatedData
    },
    countDataFor(title) {
      const data = this.selectedNotifications.find(elm => elm.title === title)
      if (data) {
        let k = 0
        data.table.forEach(table => { k += parseInt(table[2])})
        return `We identified ${k} ${data.unit}`
      }
    },

    isDataFor(title) {
      const data = this.selectedNotifications.find(elm => elm.title === title)
      if (data) {
        let k = 0
        data.table.forEach(table => { k += parseInt(table[2])})
        return k
      }
    },

    backNav () {
      this.current = 0
      this.reportView = true
      if (this.$route.path !== '/notifications') this.$router.push('/notifications')
    },
    openSpecificNotif (id) {
      const { selectedNotifications } = this
      if (selectedNotifications.find(notif => notif.id === parseInt(id))) {
        this.openDetail({id: parseInt(id)})
      } else {
        this.$Modal.error({
          title: 'the notification does\'t exist in this report'
        })
        this.$router.push('/')
      }

    },
    getSpecificReport (id, propertyId) {
      const prop = this.properties.find(elm => elm.propertyId === propertyId)
      if (!prop) {
        this.$Modal.error({
          title: 'The report you are trying to access doesn\'t exist'
        })
        this.$router.push('/')
      }
      
      this.changePropertySelected(prop)
      const params = {
        emailId : id,
        userId: [this.currentUser.id],
        properties: [prop.id]

      }
      return this.getNotificationEmails({params, uid: this._uid})
    },
    changePropertySelected (property) {
      const t = this
      if (this.property.propertyId) {
        this.property.propertyId !== this.$route.query.propertyId && this.$Modal.warning({
          title: this.property 
            ? `You're accessing a report from the property : ${property.displayName}, Facettes is no longer setted on the property : ${this.property.displayName} and the datepicker will be updated to the week ${this.$route.query.week}`
            : `You're accessing a report from the property : ${property.displayName} on the week ${this.$route.query.week}`
        })
      }
     
      const daterange = [
        moment().day('sunday').isoWeek(this.$route.query.week).subtract(6, 'days').format('YYYY-MM-DD'),
        moment().day('sunday').isoWeek(this.$route.query.week).format('YYYY-MM-DD')
      ]
      this.setSelectedDaterange(daterange)
      this.setSelectedProperty(property)

      this.$nextTick()
    },
    callbackFetch (res) {
      this.tableData = res
    },
    closeDrawer () {
      this.openDrawer = false
      if (this.$route.fullPath !== '/notifications') return this.$router.push('/notifications')
    },
    openDetail (row) {
      if (this.$route.fullPath === '/notifications') {
        // ?reportId=21&propertyId=67209&notificationId=8
        const params = `?reportId=${this.selectedReport}&propertyId=${this.property.propertyId}&notificationId=${row.id}&week=${moment(this.tableData.data[0].date).isoWeek()}`
        this.$router.push('/notifications'+ params)
      } 
      this.openDrawer = true
      this.selectedNotif = row.id
      
     
    },
    openReport (row) {
      console.log(row)
      this.reportView = false
      this.current = 1
      this.selectedReport = parseInt(row.id)
      if (this.$route.query.notificationId) {
        this.openSpecificNotif(this.$route.query.notificationId)
      }
    }
  }
}
</script>
<style>
</style>
