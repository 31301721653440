var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Drawer', {
    attrs: {
      "title": _vm.drawerTitle,
      "width": "84vw"
    },
    on: {
      "on-close": _vm.closeDrawer
    },
    model: {
      value: _vm.openDrawer,
      callback: function callback($$v) {
        _vm.openDrawer = $$v;
      },
      expression: "openDrawer"
    }
  }, [_vm.openDrawer ? [_vm.actualNotification.config ? _c('div', [_c('h4', [_c('strong', [_vm._v("Evolution per day")])]), _c('br'), _c('br'), _c('div', {
    staticStyle: {
      "max-width": "800px",
      "margin": "0 auto"
    }
  }, [_c('canvas', {
    attrs: {
      "id": "chart-".concat(_vm._uid)
    }
  })]), _c('br'), _c('br'), [_c('h4', [_c('strong', [_vm._v("Detail per day")])]), _c('br'), _c('br')]], 2) : _vm._e(), _c('div', {
    staticStyle: {
      "width": "100%",
      "text-align": "right"
    }
  }, [_c('Button', {
    staticStyle: {
      "margin-bottom": "15px"
    },
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.downloadEachCsv
    }
  }, [_vm._v(" Download data as CSV ")])], 1), _vm.actualNotification.unit === 'tracker' ? _c('Alert', {
    attrs: {
      "type": "warning",
      "show-icon": ""
    }
  }, [_vm._v(" You may click on a tracker name to see on which urls it has been identified by our bots ")]) : _vm._e(), _vm.actualNotification.title === 'Trackers detected having lifetime longer than 13 months' ? _c('Alert', {
    attrs: {
      "type": "success",
      "show-icon": ""
    }
  }, [_vm._v(" Please to get more granular informations such as exact lifte time please download the file. ")]) : _vm._e(), _vm.tableData.length === 1 ? _c('Table', {
    attrs: {
      "data": _vm.tableData[0].details_json,
      "columns": _vm.tableColumns
    }
  }) : _c('Collapse', {
    model: {
      value: _vm.collapse,
      callback: function callback($$v) {
        _vm.collapse = $$v;
      },
      expression: "collapse"
    }
  }, _vm._l(_vm.tableData, function (val, key) {
    return _c('Panel', {
      key: key,
      attrs: {
        "name": "".concat(key)
      }
    }, [_vm._v(" " + _vm._s(_vm.moment(val.date).format('LLL')) + " (" + _vm._s(val.details_json.length) + ") "), _c('Table', {
      attrs: {
        "slot": "content",
        "data": val.details_json,
        "columns": _vm.tableColumns
      },
      slot: "content"
    })], 1);
  }), 1), _c('br')] : _vm._e()], 2), _c('SldDetails', {
    attrs: {
      "sld-open": _vm.openSldDrawer,
      "force-property-id": _vm.forcePropertyId,
      "magic-daterange": _vm.defineDaterange,
      "sld-data": _vm.sldData
    },
    on: {
      "close": function close($event) {
        _vm.openSldDrawer = false;
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }