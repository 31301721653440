var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.qs ? _c('div', [_c('div', [_vm.tableData && _vm.tableData.data.length ? _c('Detail', {
    attrs: {
      "data": _vm.dailyData,
      "is-open": _vm.openDrawer
    },
    on: {
      "close-drawer": _vm.closeDrawer
    }
  }) : _vm._e()], 1), _c('Card', [_vm.current ? [_c('Steps', {
    attrs: {
      "current": _vm.current,
      "size": "small"
    }
  }, [_c('Step', {
    attrs: {
      "title": "List of reports ( ".concat(_vm.tableData ? _vm.tableData.pagination.count : '...', " )")
    }
  }), _vm.current !== 0 ? _c('Step', {
    attrs: {
      "title": "List of notifications for report #".concat(_vm.selectedReport)
    }
  }) : _vm._e()], 1), _c('br'), _c('a', {
    staticStyle: {
      "font-size": "18px"
    },
    on: {
      "click": _vm.backNav
    }
  }, [_vm.current !== 0 ? _c('i', {
    staticClass: "fas fa-arrow-left"
  }) : _vm._e()]), _c('br'), _c('br'), _vm.tableData && _vm.current == 1 ? _c('p', [_vm._v(" Weekly report detail for the property : "), _c('strong', [_vm._v(_vm._s(_vm.property.displayName))]), _c('br'), _vm._v(" Week n° " + _vm._s(_vm.moment(_vm.tableData.data.find(function (elm) {
    return elm.id === _vm.selectedReport;
  }).date).isoWeek()) + " "), _c('br'), _vm._v(" From "), _c('strong', [_vm._v(_vm._s(_vm.moment(_vm.tableData.data.find(function (elm) {
    return elm.id === _vm.selectedReport;
  }).date).subtract(6, 'day').format('LL')))]), _vm._v(" to "), _c('strong', [_vm._v(_vm._s(_vm.moment(_vm.tableData.data.find(function (elm) {
    return elm.id === _vm.selectedReport;
  }).date).format('LL')))])]) : _vm._e(), _vm._v(" "), _c('br'), _c('br')] : _vm._e(), _vm.reportView ? _c('FilterTable', {
    attrs: {
      "columns": _vm.columns,
      "name": "List of reports",
      "namespace": "notifications",
      "show-columns-selector": false,
      "fetch-items-params": {
        properties: [_vm.property.id],
        userId: [_vm.currentUser.id]
      },
      "default-paging": {
        sort: 'NotificationEmails.id',
        direction: 'desc'
      },
      "fetch-items": _vm.getNotificationEmails
    },
    on: {
      "on-items-fetched": _vm.callbackFetch
    }
  }) : _c('div', [_c('Card', {
    staticStyle: {
      "margin-bottom": "15px",
      "padding-bottom": "15px"
    }
  }, [_c('p', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_vm._v(" A - Vendor and trackers ecosystem evolution ")]), _c('span', [_vm._v("These alerts are triggered if a new tracker or a new vendors has been detected. By new we mean a tracker or a vendor that has not been identified in the previous four weeks."), _c('br'), _c('br'), _vm._v(" In all cases you should consider assessing the level of risk and related corrective actions")]), _vm._v(" "), _c('br'), _c('br'), _c('h4', [_vm._v("# 1 - New trackers identification")]), _c('br'), _c('strong', [_vm._v("# 1.1 - Non exempted 1st party tracker breach")]), _c('br'), _c('br'), _c('span', [_vm._v("We identified " + _vm._s(_vm.selectedNotifications.find(function (elm) {
    return elm.title === 'Non exempted 1st party tracker breach';
  }).value) + " new* non exempted 1st party trackers")]), _vm.selectedNotifications.find(function (elm) {
    return elm.title === 'Non exempted 1st party tracker breach';
  }).value ? _c('Button', {
    staticStyle: {
      "float": "right",
      "margin": "10px 5px"
    },
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.openDetail(_vm.selectedNotifications.find(function (elm) {
          return elm.title === 'Non exempted 1st party tracker breach';
        }));
      }
    }
  }, [_vm._v(" More details ")]) : _vm._e(), _c('br'), _c('br'), _c('br'), _c('strong', [_vm._v("# 1.2 - Non exempted 3rd party tracker breach")]), _c('br'), _c('br'), _c('span', [_vm._v("We identified " + _vm._s(_vm.selectedNotifications.find(function (elm) {
    return elm.title === 'Non exempted 3rd party tracker breach';
  }).value) + " new* non exempted 3rd party trackers.")]), _vm.selectedNotifications.find(function (elm) {
    return elm.title === 'Non exempted 3rd party tracker breach';
  }).value ? _c('Button', {
    staticStyle: {
      "float": "right",
      "margin": "10px 5px"
    },
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.openDetail(_vm.selectedNotifications.find(function (elm) {
          return elm.title === 'Non exempted 3rd party tracker breach';
        }));
      }
    }
  }, [_vm._v(" More details ")]) : _vm._e(), _c('Divider'), _c('h4', [_vm._v("# 2 - New Vendor identification")]), _c('br'), _c('strong', [_vm._v("# 2.1 - Vendor breach")]), _c('br'), _c('br'), _c('span', [_vm._v("We identified " + _vm._s(_vm.selectedNotifications.find(function (elm) {
    return elm.title === 'Vendor breach';
  }).value) + " new* vendors.")]), _vm.selectedNotifications.find(function (elm) {
    return elm.title === 'Vendor breach';
  }).value ? _c('Button', {
    staticStyle: {
      "float": "right",
      "margin": "10px 5px"
    },
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.openDetail(_vm.selectedNotifications.find(function (elm) {
          return elm.title === 'Vendor breach';
        }));
      }
    }
  }, [_vm._v(" More details ")]) : _vm._e()], 1), _c('Card', {
    staticStyle: {
      "width": "100%",
      "padding-bottom": "25px"
    }
  }, [_c('p', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_vm._v(" B - Last week privacy events ")]), _c('span', [_vm._v("These alerts are triggered if a non exempted tracker has been activated when consent is not explicitly obtained."), _c('br'), _c('br'), _vm._v(" In all cases you should consider assessing the level of risk and related corrective actions."), _c('br'), _c('br'), _vm._v(" To carry out this identification/correction process we provide you with the list of urls where the corrupted trackers have been identified.")]), _vm._v(" "), _c('br'), _c('br'), _c('h4', [_vm._v("# 3 - Last week non exempted trackers activated without explicit choice")]), _c('br'), _c('span', {
    staticStyle: {
      "clear": "both"
    }
  }, [_c('strong', [_vm._v("# 3.1 - Non exempted 1st party trackers without explicit choice")])]), _c('br'), _c('br'), _c('span', [_vm._v(_vm._s(_vm.countDataFor('Non exempted 1st party trackers without explicit choice')))]), _c('Table', {
    staticStyle: {
      "margin": "10px 0"
    },
    attrs: {
      "columns": _vm.simpleTableCols,
      "data": _vm.simpleTableData('Non exempted 1st party trackers without explicit choice')
    }
  }), _vm.isDataFor('Non exempted 1st party trackers without explicit choice') ? _c('Button', {
    staticStyle: {
      "float": "right",
      "margin-bottom": "10px"
    },
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.openDetail(_vm.selectedNotifications.find(function (elm) {
          return elm.title === 'Non exempted 1st party trackers without explicit choice';
        }));
      }
    }
  }, [_vm._v(" Daily details ")]) : _vm._e(), _c('br'), _c('br'), _c('br'), _c('span', {
    staticStyle: {
      "clear": "both"
    }
  }, [_c('strong', [_vm._v("# 3.2 - Non exempted 3rd party trackers without explicit choice ")])]), _c('br'), _c('br'), _c('span', [_vm._v(_vm._s(_vm.countDataFor('Non exempted 3rd party trackers without explicit choice')))]), _c('Table', {
    staticStyle: {
      "margin": "10px 0"
    },
    attrs: {
      "columns": _vm.simpleTableCols,
      "data": _vm.simpleTableData('Non exempted 3rd party trackers without explicit choice')
    }
  }), _vm.isDataFor('Non exempted 3rd party trackers without explicit choice') ? _c('Button', {
    staticStyle: {
      "float": "right",
      "margin-bottom": "10px"
    },
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.openDetail(_vm.selectedNotifications.find(function (elm) {
          return elm.title === 'Non exempted 3rd party trackers without explicit choice';
        }));
      }
    }
  }, [_vm._v(" Daily details ")]) : _vm._e(), _c('Divider'), _c('h4', [_vm._v("# 4 - Last week non exempted trackers activated despite refusal ")]), _c('br'), _c('span', {
    staticStyle: {
      "clear": "both"
    }
  }, [_c('strong', [_vm._v("# 4.1 - Non exempted 1st party trackers despite refusal")])]), _c('br'), _c('br'), _c('span', [_vm._v(_vm._s(_vm.countDataFor('Non exempted 1st party trackers despite refusal')))]), _c('Table', {
    staticStyle: {
      "margin": "10px 0"
    },
    attrs: {
      "columns": _vm.simpleTableCols,
      "data": _vm.simpleTableData('Non exempted 1st party trackers despite refusal')
    }
  }), _vm.isDataFor('Non exempted 1st party trackers despite refusal') ? _c('Button', {
    staticStyle: {
      "float": "right",
      "margin-bottom": "10px"
    },
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.openDetail(_vm.selectedNotifications.find(function (elm) {
          return elm.title === 'Non exempted 1st party trackers despite refusal';
        }));
      }
    }
  }, [_vm._v(" Daily details ")]) : _vm._e(), _c('br'), _c('br'), _c('br'), _c('span', {
    staticStyle: {
      "clear": "both"
    }
  }, [_c('strong', [_vm._v("# 4.2 - Non exempted 3rd party trackers despite refusal")])]), _c('br'), _c('br'), _c('span', [_vm._v(_vm._s(_vm.countDataFor('Non exempted 3rd party trackers despite refusal')))]), _c('Table', {
    staticStyle: {
      "margin": "10px 0"
    },
    attrs: {
      "columns": _vm.simpleTableCols,
      "data": _vm.simpleTableData('Non exempted 3rd party trackers despite refusal')
    }
  }), _vm.isDataFor('Non exempted 3rd party trackers despite refusal') ? _c('Button', {
    staticStyle: {
      "float": "right",
      "margin-bottom": "10px"
    },
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.openDetail(_vm.selectedNotifications.find(function (elm) {
          return elm.title === 'Non exempted 3rd party trackers despite refusal';
        }));
      }
    }
  }, [_vm._v(" Daily details ")]) : _vm._e(), _c('Divider'), _c('h4', [_vm._v(" # 5 - Last week non exempted trackers having lifetime longer than 13 months ")]), _c('br'), _c('span', {
    staticStyle: {
      "clear": "both"
    }
  }, [_c('strong', [_vm._v("# 5.1 - Trackers detected having lifetime longer than 13 months")])]), _c('br'), _c('br'), _c('span', [_vm._v(_vm._s(_vm.countDataFor('Trackers detected having lifetime longer than 13 months')))]), _c('Table', {
    staticStyle: {
      "margin": "10px 0"
    },
    attrs: {
      "columns": _vm.simpleTableCols,
      "data": _vm.simpleTableData('Trackers detected having lifetime longer than 13 months')
    }
  }), _vm.isDataFor('Trackers detected having lifetime longer than 13 months') ? _c('Button', {
    staticStyle: {
      "float": "right",
      "margin-bottom": "10px"
    },
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.openDetail(_vm.selectedNotifications.find(function (elm) {
          return elm.title === 'Trackers detected having lifetime longer than 13 months';
        }));
      }
    }
  }, [_vm._v(" Daily details ")]) : _vm._e()], 1)], 1)], 2)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }