<template>
  <div class="notifications-container"
       v-if="!loading"
  > 
    <Card style="width:100%"
          v-if="registeredNotification.length <= 0 && activeTab !== 'Configuration' && false"
    >
      <h3 style="text-transform: unset">
        Hello there, <br>it seems that you didn't have any weekly report configured
      </h3>
      <br>
      <List :split="false">
        <ListItem>- A weekly report is a group of differents KPI you can choose and get trough an email each week.</ListItem>
        <ListItem>- The weekly report is property based, if you manage many properties on a same account you can configure differents the differents KPI you want to have by email for each property.</ListItem>
        <ListItem>- The detail of the data reported in the email will also be available in Facettes where you can export it as csv for example</ListItem>
        <br>
        <p><strong> Click here to configure your first weekly report </strong></p>
      </List>
      <br>
      <br>
      <Button type="primary"
              @click="firstConfiguration"
      >
        Configure weekly report
      </Button>
    </Card>
    <Tabs
      
      style="overflow:inherit!important"
      @on-click="tabClick"
      :value="activeTab"
      :animated="false"
    >
      <TabPane
        :label="'Weekly report'"
        name="Weekly report"
      >
        <NotifList />
      </TabPane>
      <!-- <TabPane
        :label="'Configuration'"
        name="Configuration"
      > 
        <Configuration v-if="activeTab === 'Configuration'" />
      </TabPane> -->
    </Tabs>
  </div>
</template>
<script>
import List from '@/views/Notifications/List'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'Notifications',
  components: {
    // Configuration,
    "NotifList": List},
  data () {
    return {
      activeTab: 'Weekly report',
      loading: true,
      config: null,
      registeredNotification: []
    }
  },
  mounted () {
    this.getUserConfig()
  },
  computed: {
    ...mapGetters({
      property: 'scope/selectedProperty',
      user: 'user/user',
      properties: 'scope/properties',
      qs: 'scope/qs'
    })
  },


  methods: {
    ...mapActions({
      getRegisteredNotifs: 'notifications/getRegisteredNotifs'
    }),
    firstConfiguration () {
      this.config = true
      this.activeTab = 'Configuration'
    },
    tabClick (name) {
      this.activeTab = name
      name === 'Weekkly report' && this.getUserConfig()
    },
    getUserConfig (refresh = true) {
      if (refresh) {
        try {
          const key = ['NotificationEmails', 'notificationtypesUsers']
          const t = this.$store.state.notifications
          for (let i=0;i<Object.keys(t).length;i++) {
            if (Object.keys(t)[i].includes(key[0])) delete t[Object.keys(t)[i]]
            if (Object.keys(t)[i].includes(key[1])) delete t[Object.keys(t)[i]]
          }
        }
        catch (er) {
          console.log(er.message)
        }
       
      }
      this.loading = true
      const params = {
        userId: this.user.id
      }
      return this.getRegisteredNotifs({params, uid: this._uid}).then(r => {
        this.registeredNotification = r.data
        this.$store.state.notifications.registeredNotification = r.data
        this.loading = false
      })
    },
  }
}
</script>
<style>
</style>
