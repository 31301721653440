<template>
  <div>
    <Drawer :title="drawerTitle"
            width="84vw"
            @on-close="closeDrawer"
            v-model="openDrawer"
    >
      <template v-if="openDrawer">
        <div v-if="actualNotification.config">
          <h4>
            <strong>Evolution per day</strong>
          </h4>
          <br>
          <br>
          <div style="max-width:800px; margin: 0 auto;">
            <canvas :id="`chart-${_uid}`" />
          </div>
          <br>
          <br>
          <template> 
            <h4><strong>Detail per day</strong></h4>
            <br>
            <br>
          </template>
        </div>
        <div style="width: 100%; text-align:right">
          <Button type="primary"
                  style="margin-bottom:15px"
                  @click="downloadEachCsv"
          >
            Download data as CSV
          </Button>
        </div>
        <Alert type="warning"
               show-icon
               v-if="actualNotification.unit === 'tracker'"
        >
          You may click on a tracker name to see on which urls it has been identified by our bots
        </Alert>
        <Alert type="success"
               show-icon
               v-if="actualNotification.title === 'Trackers detected having lifetime longer than 13 months'"
        >
          Please to get more granular informations such as exact lifte time please download the file.
        </Alert>
        <!-- <Alert type="warning"
               show-icon
               v-if="actualNotification.unit === 'tracker'"
        >
          You can click on a domain to see each requests by this domain found by our bot (the loading may take times because of the data volumetry)
        </Alert> -->
        <Table :data="tableData[0].details_json"
               v-if="tableData.length === 1"
               :columns="tableColumns"
        />
        <Collapse v-model="collapse"
                  v-else
        >
          <Panel :key="key"
                 v-for="(val, key) of tableData"
                 :name="`${key}`"
          > 
            {{ moment(val.date).format('LLL') }} ({{ val.details_json.length }})
            <Table :data="val.details_json"
                   :columns="tableColumns"
                   slot="content"
            />
          </Panel>
        </Collapse>
        <br>
      </template>
    </Drawer>
    <SldDetails :sld-open="openSldDrawer"
                :force-property-id="forcePropertyId"
                :magic-daterange="defineDaterange"
                @close="openSldDrawer = false"
                :sld-data="sldData"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Chart from 'chart.js/auto'
import moment from 'moment'
import SldDetails from '@/views/Cookies/SldDetails'
import tableColumn from '@/mixins/tableColumn'

export default {
  name: 'NotificationDetail',
  components: {SldDetails},
  mixins: [tableColumn],
  data () {
    return {
      moment: moment,
      collapse: null,
      sldData: {},
      openDrawer: false,
      openSldDrawer: false,
      loading: true,
      dataset: [],
      chartType: 'line',
      tableData: [],
      actualNotification: {
        title: 'je suis une notif'
      },
      actualReport: {

      }
    }
  },
  props: {
    isOpen: {
      default: false,
      note: 'drawer state',
      type: Boolean
    },
    forcePropertyId: {
      default: 0,
      note: 'Property id to use if present',
      type: Number
    },
    data: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    isOpen: {
      deep: true,
      immediate: true,
      handler: async function(val, old) {
        this.openDrawer = val
        if (val) return this.getDailyData()
      }
    }
   
  },
  computed: {
    ...mapGetters({
      daterange: 'scope/selectedDaterange',
      property: 'scope/selectedProperty',
      vendorVigilanceToLetter: 'vendor/vendorVigilanceToLetter',
      riskColor: 'vendor/riskColor',
      alphaRiskColor: 'vendor/alphaRiskColor'
    }),
    defineDaterange () {
      const { data } = this
      if (!data.tableData) return false
      return moment(data.tableData[0].date).format('LL') + '  to  ' + moment(data.tableData[data.tableData.length -1].date).format('LL')
    },
    drawerTitle () {
      const { actualNotification, data } = this
      if (!data.tableData) return '...'
      return `Weekly report for ${actualNotification.title}, (${moment(data.tableData[0].date).format('LL')})`
    },
    tableColumns () {
      const arr = this.actualNotification.unit === 'vendor'
        ?  [
          {
            sortable:true, title: 'Vendor',  render: (h, params) => {
              return params.row.uuid && params.row.name
                ? h('router-link', {props: {to: `/vendors/${params.row.uuid}`}}, params.row.name)
                : h('span', {}, 'Under qualification')
            }},
          {
            title: this.$t('global.filters.is_exempted'),
            sortable: true,
            render: (h, params) => {
              const render = h('Tag', {
                props: {
                  color: 'blue'
                }
              }, 'Exempted')
              return params.row.is_exempted && params.row.is_exempted === 1 ? render : ''
            }
          },
          this.doesHelperColumn(),
          {
            title: this.$t('global.table.tcf'),
            key: 'iabv2Id',
            sortable: true
          },
          {
            title: this.$t('global.table.adstxt'),
            key: 'adstxd',
            render: (h, params) => h('span', {}, params.row.adstxtvendors && params.row.adstxtvendors.length > 0 ? this.$t('global.filters.in') : this.$t('global.filters.out')),
            // sortable: true
          },
          {
            title: this.$t('global.table.cmp'),
            render: (h, params) => h('span', {}, params.row.cmpvendors && params.row.cmpvendors.length > 0 ? this.$t('global.filters.in') : this.$t('global.filters.out') ),
            key: 'cmp',
            // sortable: true
          },
          {
            title: 'GATP',
            render: (h, params) => h('span', {}, params.row.vendor_googleadtechproviders && params.row.vendor_googleadtechproviders.length ? this.$t('global.filters.in') : this.$t('global.filters.out')),
            // sortable: true
          },
          {
            title: this.$t('global.table.country'),
            render: (h, params) => h('span', {}, (params.row.country && params.row.country.displayName) ? params.row.country.displayName : 'Under qualification'),
            // sortable: true
          },
          // this.createColumn(this.$t('global.table.tcf'), 'iabv2Id', 'Vendors.iabv2Id', null, 100),
          // this.createColumn(this.$t('global.table.adstxt'), 'adstxd', false, row => ({
          //   content: row.adstxtvendors && row.adstxtvendors.length > 0 ? this.$t('global.filters.in') : this.$t('global.filters.out')
          // })),
          // this.createColumn(this.$t('global.table.cmp'), 'cmp', false, row => ({
          //   content: row.cmpvendors && row.cmpvendors.length > 0 ? this.$t('global.filters.in') : this.$t('global.filters.out')
          // })),
          // this.createColumn('GATP', 'GAP', false, row => ({
          //   content: row.vendor_googleadtechproviders && row.vendor_googleadtechproviders.length ? this.$t('global.filters.in') : this.$t('global.filters.out')
          // })),
          // this.createColumn(this.$t('global.table.country'), 'country.displayName', 'Countries.name', row => ({
          //   content: (row.country && row.country.displayName) ? row.country.displayName : 'Under qualification'
          // }))
        
        ]
        : [
          {
            sortable:true, 
            key: 'name', 
            title: 'Name',
            render: (h, params) => h('a', {
              on: {
                click: () => this.clickTracker(params.row, 'tracker')
              }
            }, params.row.name)
          },
          {sortable:true, key:'host', title:'Domain', render: (h, params) => h('a', {
            on: {
              click: () => this.clickTracker(params.row, 'request')
            }
          }, params.row.host)},
          {sortable:true, title: 'Vendor',  render: (h, params) => {
            return params.row.vendor
              ? h('router-link', {props: {to: `/vendors/${params.row.vendor.uuid}`}}, params.row.vendor.name)
              : h('span', {}, 'Under qualification')
          }},
          {sortable:true, title: 'Category', render: (h, params) => h('span', {}, params.row.vendor ? params.row.vendor.category.name : 'N/A')},
          {sortable:true, key: 'type', title: 'Type'},
          // {sortable:true, title: 'Persistence', render:(h,params) => h('span',{}, params.row.trackerIsPersistent ? 'Yes' : 'No')},
          // {title: 'Lifetime', render: (h, params) => h('span', {}, this.getCookieAgeInMonthOrDays(params.row.lifetimeDays))},
        ]
      return arr
    }
  },
  methods: {
    ...mapActions({
      getNotificationDailies: 'notifications/getNotificationDailies'
    }),
    clickTracker (row, context) {
      row.context = context
      this.sldData = row
      this.openSldDrawer = true
    },
    sortExemption (a, b, c) {
    },
    doesHelperColumn () {
      const { showCategoryHelper } = this
      return showCategoryHelper 
        ? {
          key: 'Category.name',
          title: this.$t('global.table.category'),
          sortable: true,
          width: 150,
          render: (h, params) => h('Poptip', {
            props: {
              content:params.row.category.description,
              placement:"right",
              'word-wrap': true,
              style:{wordWrap: 'break-word'},
              width: 300,
              trigger:'hover'
            }
          }, [
            h('Badge', {
              slot:"title",
              props: {
                color: this.complianceColor[this.vendorVigilanceToLetter(params.row.category.vigilance_id)],
                text: this.$t('global.vendor.category_vigilance') + ' ' + this.vendorVigilanceToLetter(params.row.category.vigilance_id) + (params.row.category.is_exempted ? '  - Exempted' : '')
              }
            },),
            // <i class="fas fa-check-double"></i>
            params.row.category.is_exempted
              ? h('span', {},[
                h('i', {class:"fas fa-check-double", style:{marginRight:'5px', color:"#1890ff"}}),
                params.row.category.name
                
              ])
              : h('span', {}, params.row.category.name)
          ])
        }
        :  { 
          title: this.$t('global.table.category'),
          sortKey: 'Categories.name',
          render: (h, params) => {
            return params.row.category && params.row.category.is_exempted
              ? h('span', {},[
                h('i', {class:"fas fa-check-double", style:{marginRight:'5px', color:"#1890ff"}}),
                params.row.category && params.row.category.name ? params.row.category.name : ''
                
              ])
              : h('span', {}, params.row.category && params.row.category.name ? params.row.category.name : '')
          }
        }
       
     
    },
    downloadEachCsv () {
      const arrays = this.tableData
      arrays.forEach(array => {
        const data = JSON.parse(JSON.stringify(array.details_json))
        if (this.actualNotification.unit === 'vendor') {
          data.forEach(elm => {
            if (elm.adstxtvendors && elm.adstxtvendors.length) elm.adstxtvendors = 'IN'
            if (elm.adstxtvendors && elm.adstxtvendors.length === 0) elm.adstxtvendors = 'OUT'
            if (elm.category && elm.category.name) elm.category = elm.category = elm.category.name
            if (elm.cmpvendors && elm.cmpvendors.length) elm.cmpvendors = 'IN'
            if (elm.cmpvendors && elm.cmpvendors.length === 0) elm.cmpvendors = 'OUT'
            if (elm.country && elm.country.displayName) elm.country = elm.country.displayName
            delete elm.id
            delete elm.vigilance_rating
            if (elm.vendor_googleadtechproviders && elm.vendor_googleadtechproviders.length) elm.vendor_googleadtechproviders = 'IN'
            if (elm.vendor_googleadtechproviders && elm.vendor_googleadtechproviders.length === 0) elm.vendor_googleadtechproviders = 'OUT'
          })
        }
        if (this.actualNotification.unit === 'tracker') {
          data.forEach(elm => {
            if (elm.initiator_vendor) {
              elm.initiator_vendor = elm.initiator_vendor.name
            }
            if (elm.vendor) {
              elm.category = elm.vendor.category.name
              elm.vendor = elm.vendor.name
            }
          })
          
        }
        data.length && this.convertToCsv(data, this.actualNotification.title + 'on date : ' + moment(array.date).format('LL'))
      })
    },
    convertToCsv (items, name) {
      const replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
      const header = Object.keys(items[0])
      const csv = [
        header.join(','), // header row first
        ...items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
      ].join('\r\n')
      var exportedFilenmae = name + '.csv' || 'export.csv'
      var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
      if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae)
      } else {
        var link = document.createElement("a")
        if (link.download !== undefined) { // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob)
          link.setAttribute("href", url)
          link.setAttribute("download", exportedFilenmae)
          link.style.visibility = 'hidden'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        }
      }
    },
    defineDataset () {
      if (!this.actualNotification.config) return false
      this.tableData.forEach(data => {
        data.details_json.forEach(detail => {
          detail.day_date = data.date
        })        
      })
      const { tableData } = this
      this.chartType = tableData.length > 1 ? 'line' : 'bar' 
      const labels = tableData.map(data => moment(data.date).format('LL'))
      this.dataset = this.actualNotification.table.length ? this.actualNotification.table.map(elm => elm[2]) : [this.actualNotification.value]
      const data = {
        labels: labels,
        datasets: [{
          label: this.actualNotification.title,
          backgroundColor: '#6BAAD8',
          borderColor: '#6BAAD8',
          barThickness: 60,
          data: this.dataset,
          
        }]
      }
      this.renderChart(data)
    },
    renderChart (data) {
      this.chartCanvas = new Chart(document.getElementById(`chart-${this._uid}`).getContext('2d'), {
        defaultFontColor: '#707070',
        defaultFontFamily: 'Montserrat',
        type: this.chartType,
        data: data,
        options: {
          scales: {
            y: {
              beginAtZero: true
            },
            x: {
              
            }
          }
        }
      })
    },
    getCookieAgeInMonthOrDays (days) {
      days = parseInt(days)
      if (typeof days !== 'number') return 'N/A'
      if (days > -31 && days < 31) return days + ' ' + this.$t('global.time.days')
      const month = Math.round(days / 30.5)
      return month + ' ' + this.$t('global.time.months')
    },
    getDailyData () {
      const { data, property } = this
      this.actualReport = data.tableData.find(elm => elm.id === data.selectedReport)
      this.actualNotification = this.actualReport.detail_json.notifications.find(notif => notif.id === data.selectedNotif)
      const table = this.actualNotification.table
      let params = {}
      const t = this
      this.tableData = []
      if (table.length) {
        const promises = table.map(day => {
          params = {
            period: [
              moment(day[1]).format('YYYY-MM-DD'), 
              moment(day[1]).format('YYYY-MM-DD')
            ],
            properties: [this.forcePropertyId || property.id],
            notificationId: [data.selectedNotif]
          }
          return this.getNotificationDailies({params, uid: this._uid})
        })

        Promise.all(promises).then(function(values) {
          values.forEach(response => {
            t.tableData.push(response.data[0])
          })
          t.defineDataset()
        })
      } else {
        params = {
          period: [
            moment(this.actualReport.date).format('YYYY-MM-DD'), 
            moment(this.actualReport.date).format('YYYY-MM-DD')
          ],
          properties: [this.forcePropertyId || property.id],
          notificationId: [data.selectedNotif]
        }
        return this.getNotificationDailies({params, uid: this._uid})  
          .then(r => {
            this.tableData = r.data
            t.defineDataset()
          })

      }
      
    },
    closeDrawer () {
      this.$emit('close-drawer')
      this.openDrawer = false
    }
  }
}
</script>