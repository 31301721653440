var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.loading ? _c('div', {
    staticClass: "notifications-container"
  }, [_vm.registeredNotification.length <= 0 && _vm.activeTab !== 'Configuration' && false ? _c('Card', {
    staticStyle: {
      "width": "100%"
    }
  }, [_c('h3', {
    staticStyle: {
      "text-transform": "unset"
    }
  }, [_vm._v(" Hello there, "), _c('br'), _vm._v("it seems that you didn't have any weekly report configured ")]), _c('br'), _c('List', {
    attrs: {
      "split": false
    }
  }, [_c('ListItem', [_vm._v("- A weekly report is a group of differents KPI you can choose and get trough an email each week.")]), _c('ListItem', [_vm._v("- The weekly report is property based, if you manage many properties on a same account you can configure differents the differents KPI you want to have by email for each property.")]), _c('ListItem', [_vm._v("- The detail of the data reported in the email will also be available in Facettes where you can export it as csv for example")]), _c('br'), _c('p', [_c('strong', [_vm._v(" Click here to configure your first weekly report ")])])], 1), _c('br'), _c('br'), _c('Button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.firstConfiguration
    }
  }, [_vm._v(" Configure weekly report ")])], 1) : _vm._e(), _c('Tabs', {
    staticStyle: {
      "overflow": "inherit!important"
    },
    attrs: {
      "value": _vm.activeTab,
      "animated": false
    },
    on: {
      "on-click": _vm.tabClick
    }
  }, [_c('TabPane', {
    attrs: {
      "label": 'Weekly report',
      "name": "Weekly report"
    }
  }, [_c('NotifList')], 1)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }